$theme-colors: (
  "primary": #ffad36,
  "secondary": #e88409,
  "grey-base": #4d5257
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";

body {
  background-color: #181b1e;
  margin-top: 72px !important;
}

h1,
h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  text-align: center;
}

.custom-toggler.navbar-toggler {
  background-image: url('data:image/svg+xml;charset="utf-8",%3Csvg xmlns="http://www.w3.org/2000/svg" …miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22/%3E%3C/svg%3E"');
}

.avatar {
  width: 80px;
  border-radius: 80px;
  border: 2px solid #ffad36;
}

.navbar-brand img {
  height: 46px;
}

.built-with-container .badge {
  margin-right: 3px;
}

.jumbotron {
  padding: 1rem 2rem;
  text-align: center;
}

.jumbotron.jumbo-404 {
  background-color: white;
}

.card {
  margin-bottom: 10px !important;
  height: 100%;
}

.subhead {
  text-align: center;
  font-family: "Poppins Light", "Roboto Light", sans-serif;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
}
